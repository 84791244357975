import * as React from 'react'
import * as Api from '../api/api'
import {EditFormBase} from "../forms";
import Page from "../components/Page";
import IndexLayout from "../layouts";
import Container from "../components/Container";
import {navigate} from "gatsby";

export class Update extends React.Component<any, Api.DirectAlertTableEntity & {isLoading: boolean}> {
	constructor(props, context) {
		super(props, context);
		this.state = {isLoading: false};
	}

	public componentDidMount(): void {
		const ei = (typeof sessionStorage !== 'undefined' && sessionStorage.getItem('editingItem'));
		this.setState(JSON.parse(ei || '{}'));
	}

	public handleForm2(e) {
		e.preventDefault();
		this.setState({isLoading: true}, () =>
			new Api.DirectAlertApi().updateRegistrationPOST({
				req: this.state,
				authToken: '',
			}).then((result) => {
				if (result.success) {
					navigate('/thanks');
					// location.href = '/thanks';
				} else {
					this.setState({isLoading: false});
				}
			}, (err) => {
				console.log(err);
				this.setState({isLoading: false});
			})
		);
	}

	public render() {
		return  (
			<IndexLayout>
				<Page>
					<Container>
						{this.renderInside()}
					</Container>
				</Page>
			</IndexLayout>
		);
	}

	public renderInside() {
		return (
			<div>
				<h1>Contact Information</h1>
				<p><b>Great!</b> We found your account. Please let us know how you'd like to be contacted.</p>
				<form onSubmit={(e) => this.handleForm2(e)}>
					{EditFormBase.boundTextboxValue(
						'Email address',
						this.state.email || '',
						(email) => this.setState({email}),
						'',
						false,
						this.state.isLoading)
					}
					{EditFormBase.boundCheckboxValue(
						'Email me in case of emergency',
						this.state.wantEmail || false,
						(e) => this.setState({wantEmail: e}),
						this.state.isLoading)
					}
					{EditFormBase.boundTextboxValue(
						'Home Phone',
						this.state.homePhone || '',
						(e) => this.setState({homePhone: e.toUpperCase()}),
						'',
						false,
						this.state.isLoading)
					}
					{EditFormBase.boundCheckboxValue(
						'Call me at home in case of emergency',
						this.state.wantHomeCall || false,
						(e) => this.setState({wantHomeCall: e}),
						this.state.isLoading)
					}
					{EditFormBase.boundTextboxValue(
						'Work Phone',
						this.state.workPhone || '',
						(e) => this.setState({workPhone: e.toUpperCase()}),
						'',
						false,
						this.state.isLoading)
					}
					{EditFormBase.boundCheckboxValue(
						'Call me at work in case of emergency',
						this.state.wantWorkCall || false,
						(e) => this.setState({wantWorkCall: e}),
						this.state.isLoading)
					}
					{EditFormBase.boundTextboxValue(
						'Mobile Phone',
						this.state.cellPhone || '',
						(e) => this.setState({cellPhone: e.toUpperCase()}),
						'',
						false,
						this.state.isLoading)
					}
					{EditFormBase.boundCheckboxValue(
						'Call me on my mobile phone in case of emergency',
						this.state.wantCellCall || false,
						(e) => this.setState({wantCellCall: e}),
						this.state.isLoading)
					}
					{EditFormBase.boundCheckboxValue(
						'Text me on my mobile phone in case of emergency (regular SMS rates)',
						this.state.wantCellSMS || false,
						(e) => this.setState({wantCellSMS: e}),
						this.state.isLoading)
					}
					<p><button className="btn btn-primary" type="submit" disabled={this.state.isLoading}>
						<i className="icon-white icon-check" />
						{this.state.isLoading ? 'Please wait...' : 'Submit'}
					</button></p>
					<p><img alt="[Lock icon]" src="https://vxcv.com/icons/lock.png" /> This page is secure.</p>
				</form>
			</div>
		);
	}
}

export default Update;
